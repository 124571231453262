// NOTE: These files should not have any more changes made to them
// before they are removed. If this poses a problem contact the front
// end engineering manager
define(['jquery', 'siteObj', 'countdown'], function($, siteObj) {
  
  var config = window.requirejs.s.contexts._.config.config.dealsCountdown;
  var dealsCountdown = {
    initDealsCountdown: function() {
      //Sets the rules for the countdown timer.

      var dateArray = siteObj.features.countdown.servertime.split('-'); // Build an Array from the time passed to to the siteObj
      var year = parseInt(dateArray[0], 10),
        month = parseInt(dateArray[1], 10) - 1, //This needs to have 1 removed because in javascript months go from 0 - 11
        date = parseInt(dateArray[2], 10),
        hour = parseInt(dateArray[3], 10),
        minute = parseInt(dateArray[4], 10),
        second = parseInt(dateArray[5], 10),
        dayOfTheWeek = dateArray[6],
        today = new Date(year, month, date, hour, minute, second, 0), // The time for today build from the server time
        endHour = (typeof config !== 'undefined' && typeof config.endHour !== 'undefined') ? config.endHour : 21, // The end hour for the countdown
        endMinute = (typeof config !== 'undefined' && typeof config.endMinute !== 'undefined') ? config.endMinute : 0, // The end minute for the countdown
        endSecond = (typeof config !== 'undefined' && typeof config.endSecond !== 'undefined') ? config.endSecond : 0, // The end second for the countdown
        endMilli = (typeof config !== 'undefined' && typeof config.endMilli !== 'undefined') ? config.endMilli : 0; // The end milliseconds for the countdown


      // Time Zones GMT offsets
      if (siteObj.countryCode === 'fi') {
        endHour = endHour - 2;
      }

      if (siteObj.countryCode === 'es' ||
        siteObj.countryCode === 'nl') {
        endHour = endHour + 1;
      }


      if ((typeof config !== 'undefined' && typeof config.weekends !== 'undefined') && config.weekends === false && (dayOfTheWeek === 'Sunday' || dayOfTheWeek === 'Saturday')) {
        // If weekends are defined on the module config as false then turn off the timer
        endHour = 0;
      } else if (dayOfTheWeek === 'Sunday') {
        // If it's a Saturday don't show the timer before 24 hours before the cut-off, then count down from 24 hours.
        endHour = 24;
      }
      var endTime = new Date(year, month, date, endHour, endMinute, endSecond, endMilli);

      if (dayOfTheWeek === 'Saturday') { // Don't show the countdown on a Friday
        endTime = null;
      }

      var $countdownTimerDailyDeals = $('.countdown-timer-daily-deals');

      if ($countdownTimerDailyDeals.length) {

        $countdownTimerDailyDeals.countdownTimer({ // Initiate the countdown timer.
          parentHide: 'countdown-wrap',
          cutOffTime: endTime,
          serverTime: today,
          timerClass: '',
          showLabels: false,
          seperator: ':'
        });
      }
    }
  };

  dealsCountdown.initDealsCountdown();
  return dealsCountdown;
});
